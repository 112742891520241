import React, { PureComponent } from 'react';
import logo from './logo.svg';
import './App.css';
import HomePage from './containers/HomePage';
import Tracking from './containers/Tracking';
import PrimarySearchAppBar from './components/NavHeader';
import { BrowserRouter, Switch, Route, Link, NavLink } from 'react-router-dom';
import Footer from './components/Footer';
import NotFound from './containers/NotFound';

class App extends PureComponent {

	render() {
		return (
			<div className="App">
				<BrowserRouter>
					<PrimarySearchAppBar />
					<Switch>
						<Route exact path="/" component={HomePage} />
						<Route exact path="/tracking" component={Tracking} />
						<Route component={NotFound} />
						
					</Switch>
					<Footer />
				</BrowserRouter>
			</div>
		);
	}
}

export default App;
