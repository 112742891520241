import React, { PureComponent } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import './css/OrderItem.css';
import moment from 'moment';
import { isUndefined, isNull } from 'util';

export default class OrderItem extends PureComponent {
	checkKeepingDate = orderDetail => {
		const { products, created_at } = orderDetail;
		if (!orderDetail.hasOwnProperty('created_at')) return 0;
		const createDate = moment(created_at ? created_at : 0, 'X');
		const idx = products.findIndex(item => item['film_type'] === 'slide');
		// console.log(idx);
		if (idx == -1) {
			return createDate.add(15, 'days').format('DD/MM/YYYY');
		} else {
			return createDate.add(30, 'days').format('DD/MM/YYYY');
		}
	};

	render() {
		const { orderDetail } = this.props;
		// console.log(this.props);
		const orderStatusName = {
			pending: 'Đang xử lý/ Processing',
			done: 'Đã xong/ Done'
		};
		const filmStatusName = {
			keeping: 'Đang giữ tại LLab/ Archiving',
			taken: 'Đã lấy/ Taken',
			false: 'Đã hủy/ Destroyed'
		};
		if (orderDetail == {}) return <div>Loading</div>;
		const { order_number, film_status, order_status, order_type, products, order_note } = orderDetail;
		const { branch } = this.props;
		const created_at = orderDetail.hasOwnProperty('created_at') ? orderDetail['created_at'] : 0;
		const updated_at = orderDetail.hasOwnProperty('updated_at') ? orderDetail['updated_at'] : 0;
		const createDate = moment(created_at, 'X');
		const keepingDate = this.checkKeepingDate(orderDetail);
		const updatedDateString = moment(updated_at, 'X').format('h:mm:ss a - DD/MM/YYYY');

		const renderOrderStatus =
			order_status === 'pending'
				? 'Đang xử lý/ Processing'
				: `Đã xong/ Done ${updated_at ? `lúc/ at ${updatedDateString}` : ''}`;
		const keepingString = keepingDate
			? <span>Giữ âm bản đến ngày/ Keep until: <strong>{keepingDate}</strong></span>
			: <span>'Đang giữ âm bản tại LLab/ Keep film negative at LLab'</span>;
		let renderFilmStatus =
			film_status === 'keeping' ? keepingString : film_status === 'taken' ? 'Đã lấy/ Taken' : 'Đã hủy/ Discard';
		return (
			<Card className="card-item">
				<CardContent className="card-content">
					<Typography color="textSecondary">
						<div className="item-detail-row">
							Nơi gửi film/ Dropped at: <strong>LLab {branch}</strong>
						</div>
					</Typography>
					<Typography className="client-name">
						<div className="item-detail-row">
							Đơn hàng/ Order: <strong>{order_number}</strong>{' '}
							{created_at && (
								<span>
									<span>- Ngày khởi tạo/ Created on: </span>
									<strong>{createDate.format('h:mm:ss a - DD/MM/YYYY')}</strong>
								</span>
							)}
						</div>
					</Typography>

					<Typography variant="body2" component="div">
						<div className="item-detail-row">
							<span>Tình trạng/ Process status:</span> <strong>{renderOrderStatus}</strong>
						</div>
					</Typography>

					<Typography variant="body2" component="div">
						<div className="item-detail-row">
							{renderFilmStatus}
						</div>
					</Typography>
					{order_note && (
						<Typography variant="body2" component="div">
							<div className="item-detail-row">
								<strong>Note:</strong> <span style={{color: "red"}}>{order_note}</span>
							</div>
						</Typography>
					)}
					<Typography variant="body2" component="div">
						<div style={{ marginTop: '25px', fontSize: '18px' }}>
							<strong>Chi tiết:</strong>
						</div>
					</Typography>
					{!isUndefined(products) &&
						products.map((item, index) => (
							<Typography key={index} variant="body2" component="div" className="product-detail-item">
								<div className="item-detail-row">
									<strong>
										#{index + 1} - {item.film_size} - {item.film_type}
									</strong>
								</div>
								<div className="item-detail-row">
									<span>Số lượng/ Quant: </span>
									<strong>{item.quantity}</strong>
								</div>
								<div className="item-detail-row">
									Tình trạng/ Status: <strong>{item.done ? 'Đã xong' : 'Đang xử lý'}</strong>
								</div>
								{item['note'] && 								<div className="item-detail-row">
									Note <strong>{item['note']}</strong>
								</div>}
								<div className="item-detail-row">
									<a href="https://llab.vn/lich-trang-film-cu-the/">Lịch xử lý đơn hàng/Processing schedule</a>
								</div>
							</Typography>
						))}
				</CardContent>
			</Card>
		);
	}
}
