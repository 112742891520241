import React, { PureComponent } from 'react';
import OderItem from '../components/OrderItem';
import { db } from '../db';
import './css/TrackingPage.css';
import PropTypes from 'prop-types';

class Tracking extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			searchValue: '',
			phoneNumber: '',
			branchName: 'district_1',
			ordersDetail: {},
			isError: false
		};
	}

	handleSearch(searchValue,phoneNumber, branchName) {
		// const { firebase } = this.props;
		if (searchValue) {
			const searchKey = searchValue[0].toUpperCase() == 'N' ? searchValue : `N${searchValue}`;
			try {
				db.ref(`orders_tracking/${branchName}/${searchKey}`).once('value').then(snapshot => {
					const ordersDetail = snapshot.val();
					// console.log(ordersDetail);
					if (ordersDetail) {
						if (ordersDetail['user_phone'] == phoneNumber) {
							this.setState({ ordersDetail, isError: false });
						} else {
							this.setState({ ordersDetail: {}, isError: true });
						}
						// this.setState({ ordersDetail, isError: false });
					} else {
						this.setState({ ordersDetail: {}, isError: true });
					}
				});
			} catch (err) {
				console.log(err);
			}
		}
	}

	handleSubmit = event => {
		const { searchValue, phoneNumber, branchName } = this.state;
		event.preventDefault();
		if (!searchValue) {
			window.alert('Vui lòng điền đầy đủ thông tin.');
			return;
		}
		this.handleSearch(searchValue, phoneNumber, branchName);
	};

	render() {
		const { ordersDetail, searchValue, phoneNumber, isError, branchName } = this.state;
		const branchTextName = {
			district_1: 'Quận 1',
			district_3: 'Quận 3'
		};
		// console.log(this.state)
		return (
			<div className="container tracking-wrapper">
				<div className="tracking-caption">
					<strong>Theo dõi đơn hàng/ Order tracking</strong>
				</div>
				<div className="tracking-description">
					Vui lòng điền mã đơn hàng (Đính kèm trong email xác nhận) để xem chi tiết đơn hàng.
					<br />
					Please fill out the order code (Attached in confirmation email) to see order details.
				</div>
				<form onSubmit={e => this.handleSubmit(e)}>
					<div className="input-form-wrapper">
						<div className="row">
							<div className="col-6 col-sm-4">
								{' '}
								<label htmlFor="input-orderid" className="input-label">
									Mã đơn hàng/ Order number:
								</label>
							</div>
							<div className="col-6 col-sm-4">
								{' '}
								<input
									type="text"
									className="form-control orderid-input"
									value={searchValue}
									ref="searchBox"
									onChange={e => this.setState({ searchValue: e.target.value })}
								/>
							</div>

							<div className="w-100 d-none d-md-block" />
						</div>
						<div className="row">
							<div className="col-6 col-sm-4">
								{' '}
								<label htmlFor="input-orderid" className="input-label">
									Số điện thoại/ Phone number:
								</label>
							</div>
							<div className="col-6 col-sm-4">
								{' '}
								<input
									type="text"
									className="form-control orderid-input"
									value={phoneNumber}
									ref="searchBox"
									onChange={e => this.setState({ phoneNumber: e.target.value })}
								/>
							</div>

							<div className="w-100 d-none d-md-block" />
						</div>
						<div className="row">
							<div className="col-6 col-sm-4">
								{' '}
								<label htmlFor="input-orderid" className="input-label">
									Chi nhánh/ Branch:
								</label>
							</div>
							<div className="col-6 col-sm-4" style={{ textAlign: 'left' }}>
								{/* <div className="row"> */}
									<div style={{marginBottom: '7px'}}>
										<input
											type="radio"
											name="branch"
											value="district_1"
											onChange={e => this.setState({ branchName: e.target.value })}
											checked={branchName === 'district_1' ? true : false}
										/>{' '}
										Quận 1
									</div>
									<div>
										<input
											type="radio"
											name="branch"
											value="district_3"
											onChange={e => this.setState({ branchName: e.target.value })}
											checked={branchName === 'district_3' ? true : false}
										/>{' '}
										Quận 3
									</div>
								{/* </div> */}
							</div>
						</div>
						<div className="col-6 col-sm-" />
						<div className="row">
							<div className="col-6 col-sm-4" />

							<div className="col-6 col-sm-4">
								<button
									type="button"
									className="btn btn-outline-secondary search-button"
									onClick={() => this.handleSearch(searchValue, phoneNumber, branchName)}
								>
									Tìm kiếm
								</button>
							</div>
						</div>
					</div>
				</form>

				{isError && (
					<div>
						<strong>Không tìm thấy đơn hàng, hoặc số điện thoại không trùng khớp.</strong>
						<br />
						<strong>No order infomation found or your phone number does not match.</strong>
					</div>
				)}
				{Object.entries(ordersDetail).length > 0 && (
					<OderItem orderDetail={ordersDetail} branch={branchTextName[branchName]} />
				)}
			</div>
		);
	}
}

Tracking.propTypes = {};

export default Tracking;
