import firebase from 'firebase';

  const firebaseConfig = {
    apiKey: 'AIzaSyAYYWyvBMfnVKwmbElQspUIPcjf7ule0tk',
    authDomain: 'llab-firebase-worker.firebaseapp.com',
    databaseURL: 'https://llab-firebase-worker.firebaseio.com',
    projectId: 'llab-firebase-worker',
    storageBucket: 'llab-firebase-worker.appspot.com',
    messagingSenderId: '937187518260',
    appId: '937187518260'
  };
  // console.log(firebaseConfig);
const firebaseApp = firebase.initializeApp(firebaseConfig);


const db = firebaseApp.database();

export { db };