import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Container } from '@material-ui/core';
// import PrimarySearchAppBar from '../components/NavHeader';
// import OrderList from '../components/OrderList';
// import Login from './Login';
import Tracking from './Tracking';

class HomePage extends PureComponent {
    
    render() {
        // this.props.history.push('./tracking');
        return (
            <div>
                {/* <PrimarySearchAppBar /> */}
                <Container maxWidth="md">
                    <Tracking />
                    {/* <OrderList /> */}
                </Container>
            </div>
        );
    }
}

HomePage.propTypes = {

};

export default HomePage;